import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';

export const useHeader = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const links = router.pathname.includes('/profile')
    ? [
        {
          title: t('header.portfolio'),
          path: '/profile/portfolio',
        },
        {
          title: t('deposits.find_deposits_button'),
          path: '/multibanking-platform',
        },
        {
          title: t('global.faq_and_support'),
          path: '/profile/faq',
        },
      ]
    : [
        {
          title: t('header.all_deposits'),
          path: '/fixed-deposits',
        },
        {
          title: t('header.savings-accounts'),
          path: '/savings-accounts',
        },
        {
          title: t('header.blog'),
          path: '/blog',
        },
        {
          title: t('header.statistics'),
          path: '/statistics/maximum-interest-rates-in-different-countries',
        },
        {
          title: t('header.banks'),
          path: '/banks',
        },
        {
          title: t('header.multibanking-platform'),
          path: '/multibanking-platform',
        },
      ];

  return {
    links,
  };
};
